.bussinessUnit {
  position: relative;
  width: 100%;
  height: 100%;

  &:not(:last-of-type) {
    @include responsive(768) {
      margin-bottom: 1rem; }
    @include responsive(480) {
      margin-bottom: .5rem; } }

  &_agrotell {
    .bussinessUnit__overlay {
      background-color: transparentize($br-darker-gray, .1); } }

  &_tell {
    .bussinessUnit__overlay {
      background-color: transparentize($br-darker-gray, .1); } }

  &_zoko {
    .bussinessUnit__overlay {
      background-color: transparentize($br-extra, .1); } }

  &_creando {
    .bussinessUnit__overlay {
      background-color: transparentize($br-extra-2, .1); } }

  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(24%, 1fr));
    grid-auto-rows: 768px;
    gap: 1.125rem;
    position: relative;
    padding: 1.125rem;
    box-sizing: border-box;
    @include responsive(768) {
      grid-template-columns: 1fr;
      grid-auto-rows: 600px; }
    @include responsive(480) {
      gap: .5rem;
      padding: 0; }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50%;
      background-color: $br-lighter-gray; }


    &[data-count="4"] {
      @include responsive(1440) {
        grid-template-columns: repeat(2, 1fr); }
      @include responsive(768) {
        grid-template-columns: 1fr; } } }

  &__back {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; }

  &__logo {
    max-width: 140px;
    max-height: 80px; }

  > .bussinessUnit__logo {
    position: absolute;
    top: 3rem;
    left: 1.5rem;
    @include responsive(768) {
      top: 2rem; } }

  &__overlay {
    @extend %flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 3rem 1.5rem 1.5rem;
    box-sizing: border-box;
    background-color: transparentize($br-primary, .1);
    color: #fff;
    transition: all ease-in-out .3s;
    clip-path: inset(0 100% 0 0);
    @include responsive(768) {
      padding-top: 2rem; }

    &.mouseIn {
      animation: mouseIn ease-in-out .3s forwards; }
    &.mouseOut {
      animation: mouseOut ease-in-out .3s forwards; } }

  &__header {
    @extend %flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .bussinessUnit__logo {
      filter: brightness(0)invert(1); }

    .cta {
      @include responsive(480) {
        span {
          display: none; } } } }

  &__description {
    width: 100%;
    margin-top: 2rem; }

  &__footer {
    @extend %flex;
    flex-wrap: wrap;

    li {
      width: calc(100%/3 - 1rem);
      margin-top: 1.5rem;
      margin-right: 1rem;
      @include responsive(1440) {
        width: calc(50% - 1rem); }
      @include responsive(768) {
        width: calc(100%/3 - 1rem); }
      @include responsive(480) {
        width: calc(50% - 1rem); }

      img {
        width: 2rem;
        @include responsive(1600) {
          width: 1.5rem; } }

      span {
        display: inline-block;
        width: 100%; } } } }

@keyframes mouseIn {
  0% {
    clip-path: inset(0 100% 0 0); }
  100% {
    clip-path: inset(0); } }

@keyframes mouseOut {
  0% {
    clip-path: inset(0); }
  100% {
    clip-path: inset(0 0 0 100%); } }
