::-webkit-scrollbar {
  background: $br-lighter-gray;
  overflow: visible;
  width: 4px;
  height: 4px; }

::-webkit-scrollbar-thumb {
  background-color: $br-primary; }

::-webkit-scrollbar-thumb:hover {
  background-color: darken($br-primary, 5%); }

::-webkit-scrollbar-thumb:horizontal {
  min-width: 40px;
  border-left: 0; }

::-webkit-scrollbar-thumb:vertical {
  min-height: 40px; }

::-webkit-scrollbar-track {
  background-color: transparent; }

::-webkit-scrollbar-track:hover {
  background-color: transparent; }

::-webkit-scrollbar-corner {
  background: transparent; }
