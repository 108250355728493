.form {

  &_contact {
    margin-top: -1rem;
    @include responsive(768) {
      margin-top: 0; } }

  &__step {
    width: calc((100% - 2rem)/3);
    margin-bottom: 1.5rem;
    position: relative;
    z-index: 1;
    @include responsive(768) {
      width: 100%;
      margin-left: 1rem;
      margin-bottom: 0;
      padding-left: 2rem;
      padding-bottom: 1.5rem; }

    &:not(:last-of-type) {
      margin-right: 1rem;
      @include responsive(768) {
        margin-right: 0;
        border-left: 2px solid #fff; } }

    .step {
      display: block;
      width: 2rem;
      height: 2rem;
      margin-bottom: 1rem;
      border-radius: 50%;
      background-color: #fff;
      line-height: 2rem;
      text-align: center;
      @include responsive(768) {
        position: absolute;
        left: -1rem;
        top: -.25rem; } } }

  &__input {
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: 1rem; }

    textarea {
      height: 134px; }

    input,
    textarea {
      width: 100%;
      padding: 1.5rem 1rem .5rem 1rem;
      box-sizing: border-box;
      background-color: #fff;
      border: 1px solid $br-light-gray;
      border-bottom-width: 2px;
      border-bottom-color: $br-darker-gray;
      transition: all ease-out .2s;
      color: $br-darker-gray; }

    label {
      position: absolute;
      top: 0.5rem;
      left: 1rem;
      right: 1rem;
      font-size: .8rem;
      color: $br-dark-gray;
      transition: all ease-out .2s; }

    &.is-label-down {

      input,
      textarea {
        padding: 1rem; }

      label {
        top: 1.125rem;
        font-size: 1rem;
        color: $br-darker-gray; } }

    &.is-wrong {
      input,
      textarea {
        border-color: $br-primary; } } }

  select.form__input {
    width: 100%;
    padding: 1rem 2.5rem 1rem 1rem;
    box-sizing: border-box;
    background: #fff url('../img/icons/select__arrow.svg') right center no-repeat;
    border: 1px solid $br-light-gray;
    border-bottom-width: 2px;
    border-bottom-color: $br-darker-gray;
    font-weight: 300; }

  &__validation {
    display: none;
    width: 100%;
    padding: .75rem 1rem;
    box-sizing: border-box;
    background-color: #f8dedc;
    color: $br-darker-primary;

    &.is-ok {
      background-color: #e3eadf;
      color: darken($br-extra, 2%); } }

  #validation-job {
    margin-top: 1rem;
    margin-bottom: 0; }

  #submit {
    margin-left: auto; }

  #submit-job {
    float: right;
    margin-top: 1.5rem; } }
