html, .root {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300; }

body, .article {
  font-family: $ft-primary;
  font-size: 1rem;
  line-height: 1.5;
  margin: auto; }

h1, .h1 {
  font-size: clamp(1.5rem, 5vw, 2.5rem);
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.5em;
  text-transform: none; }

h2, .h2 {
  font-size: clamp(1.5rem, 5vw, 2.25rem);
  line-height: 1.25;
  margin-top: 1.25em;
  margin-bottom: 0.5em; }

h3, .h3 {
  font-size: clamp(1.125rem, 5vw, 1.75rem);
  line-height: 1.5;
  margin-top: 1.5em;
  margin-bottom: 0.5em; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 500;
  margin-top: 1.5em;
  margin-bottom: 0.5em; }

h5, .h5 {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  margin-top: 1.5em;
  margin-bottom: 0.5em; }

p, ul, ol, pre, table, blockquote {
  margin-top: 0px;
  margin-bottom: 1em; }

p.lg {
  font-size: 1.25rem;
  @include responsive(1366) {
    font-size: 1.125rem; }
  @include responsive(480) {
    font-size: 1rem; } }

p.md {
  font-size: 1.125rem;
  @include responsive(1366) {
    font-size: 1rem; } }

ul ul, ol ol, ul ol, ol ul {
  margin-top: 0px; }

// div ul
//   padding-left: 1rem

//   li
//     position: relative
//     &::before
//       content: ''
//       position: absolute
//       left: -1em
//       top: calc(.75em - 3px)
//       display: block
//       width: 6px
//       height: 6px
//       background-color: $br-darker-gray
//       border-radius: 50%

//     &:not(:last-of-type)
//       margin-bottom: 0.5em
