.contact {

  &__col {
    @extend %clearfix;
    flex: 0 1 auto;
    width: calc(50% - .75rem);
    @include responsive(768) {
      width: 100%; }

    &_sm {
      @include responsive(769, min) {
        width: calc(100%/12*3); } }

    &_lg {
      position: relative;
      @include responsive(769, min) {
        width: calc(100%/12*9); } }

    &_dark {
      padding-top: clamp(3rem, 5vw, 6rem);
      padding-bottom: clamp(6rem, 10vw, 12rem);
      background-color: $br-dark-gray;
      color: #fff; }

    h5, h4, h3, h2, h1 {
      &:first-child {
        margin-top: 0; } } }

  &__head {
    @extend .air;
    @extend .air_horz;
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
    @include responsive(768) {
      padding-top: 3rem;
      padding-bottom: 1.5rem; }

    h4 {
      margin: 0; } }

  &__body {
    @extend .air;
    @extend .air_horz;
    @extend %flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    &.info {
      flex-direction: column;
      margin-top: 2rem;
      @include responsive(768) {
        margin-top: 0;
        margin-bottom: 3rem; } }

    a {
      display: inline-block;
      margin-bottom: .5rem;
      color: $br-primary;
      font-weight: 500; }

    p {
      width: 100%; }

    .contact__col:not(:last-of-type) {
      margin-bottom: 3rem; } } }
