.value {
  flex: 0 1 auto;
  width: 100%;
  &:not(:last-of-type) {
    margin-right: 1.25rem;
    @include responsive(480) {
      margin-right: 0;
      margin-bottom: 3rem; } }

  &__img {
    position: relative;
    width: 100%;

    &::before {
      content: '';
      display: block;
      padding-top: 100%; }

    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -998; } }

  &__misc {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-top: 2px solid $br-lighter-gray;
    font-size: 2rem;

    i {
      display: block;
      width: 3rem;
      padding: 0.5rem;
      box-sizing: border-box;
      background-color: $br-lighter-gray;
      color: $br-darker-primary;

      &::before {
        margin: 0; } } }

  &__container {
    @extend %flex;
    position: relative;
    @include responsive(768) {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: calc(var(--valueTop) - 3rem);
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $br-lighter-gray;
        z-index: -999; } }
    @include responsive(480) {
      display: block; } } }
