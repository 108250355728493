// Para el correcto funcionamiento, llamar en el html al script "autoHideNav.js"
// Ademas, el nav debe tener la clase .autoHideNav
// Fuente: https://medium.com/@mariusc23/hide-header-on-scroll-down-show-on-scroll-up-67bbaae9a78c

.autoHideNav {
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s ease 0s;

  &.is-hidden {
    transform: translateY(-100%); } }
