.section {
  --sectionTop: 0;
  --valueTop: 0;
  position: relative;
  padding-top: clamp(6rem, 10vw, 12rem);
  padding-bottom: clamp(6rem, 10vw, 12rem);

  &_values::after {
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--sectionTop) + var(--valueTop) - 3rem);
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $br-lighter-gray;
    z-index: -999;
    @include responsive(768) {
      top: 50%; } }

  &_contact {

    .contact__col_sm {
      .contact__head {
        @include responsive(768) {
          border-top: 2px solid $br-lighter-gray; }

        &::after {
          width: calc((100vw - 1605px)/2 + 100%);
          right: 0;
          @include responsive(1600) {
            width: 100%; } } } }

    .contact__col_lg {
      .contact__head::after {
        width: calc((100vw - 1605px)/2 + 100%);
        left: 0;
        background-color: #fff;
        @include responsive(1600) {
            width: 100%; } }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: calc((100vw - 1605px)/2 + 100%);
        height: calc(var(--sectionTop) + 100%);
        background-color: $br-lighter-gray;
        z-index: -999;
        @include responsive(1600) {
          width: 100%; } } }

    .contact__head {

      &::after {
        content: '';
        position: absolute;
        display: block;
        height: 2px;
        bottom: 0;
        background-color: $br-lighter-gray;
        z-index: 1;
        @include responsive(768) {
          display: none; } } } }

  &_rrhh {
    padding: 0;

    .contact__col_sm {
      @include responsive(768) {
        display: none; }
      @include responsive(769, min) {
        width: 25%; }
      @include responsive(1601, min) {
        width: calc((1600px/12*3) + ((100% - 1600px)/2)); } }
    .contact__col_lg {
      @include responsive(769, min) {
        width: 75%; }
      @include responsive(1601, min) {
        width: calc((1600px/12*9) + ((100% - 1600px)/2)); } }

    .contact__head,
    .contact__body {
      max-width: 1200px; } }

  &__row {
    @extend %flex;
    flex-wrap: wrap; }

  &__col {
    @extend .air;
    @extend .air_horz;
    flex: 0 1 auto;
    @include responsive(768) {
      &:not(:last-of-type) {
        margin-bottom: 3rem; } }

    &_sm {
      @include responsive(769, min) {
        width: calc(100%/12*4.5); } }
    &_lg {
      @include responsive(769, min) {
        width: calc(100%/12*7);
        margin-left: calc(100%/12*0.5); } } }

  h2 {
    margin-top: 0;
    margin-bottom: 1.25em; } }
