@mixin cta-hover($color) {
  background-color: darken($color, 5%); }

@mixin cta-active($color) {
  background-color: darken($color, 10%); }

button.cta {
  border: none; }

.cta {
  @extend %bbx;
  position: relative;
  display: inline-block;
  padding: 0.5rem 1rem;

  background-color: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;

  font-family: $ft-primary;
  color: $br-darker-primary;
  text-decoration: none;
  font-size: 1.125rem;
  text-align: center;
  font-weight: 500;

  outline: none;
  box-shadow: none;
  transition: background-color 0.3s ease-in-out 0s;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    display: block;
    width: 0;
    height: 100%;
    background-color: lighten($br-lighter-gray, 2%);
    z-index: -1;
    transition: width 0.3s ease-in-out 0s; }

  &:hover,
  &:active {
    text-decoration: none; }

  &:hover,
  &:active,
  &:focus {
    &::before {
      width: 100%; } }

  &:focus {
    outline: 2px double $br-darker-primary;
    outline-offset: 2px; }

  &:active {
    &::before {
      background-color: darken($br-lighter-gray, 5%); } }

  &_alt {
    color: #fff;
    &::before {
      background-color: transparentize(#fff, .9);
      z-index: 0; }

    &:active {
      &::before {
        background-color: transparentize(#fff, .8); } } }

  &_disabled {
    pointer-events: none;
    color: $br-light-gray;

    &::before {
      width: 100%;
      background-color: transparentize(#000, .95); } } }
