.hero {
  @extend %flex;
  position: relative;
  min-height: calc(100svh - 1rem);
  border-bottom: 1rem solid $br-darker-primary;
  @include responsive(768) {
    min-height: 0;
    flex-direction: column;
    border-bottom: .5rem solid $br-darker-primary; }

  &__col {
    width: 50%;
    @include responsive(768) {
      width: 100%;

      &.swiper {
        height: 100vw; } } }

  &__wrapper {
    width: 80%;
    max-width: 720px;
    margin-top: 10%;
    margin-left: calc(50vw - 800px);
    @include responsive(1600) {
      width: 90%;
      max-width: none;
      margin-left: .5rem; }
    @include responsive(768) {
      width: 100%;
      margin-top: 0;
      margin-left: 0;
      margin-right: 0; } }

  h1 {
    margin-bottom: 15vh;
    @include responsive(768) {
      margin-bottom: 10vh; } }

  &__nav {
    @extend %flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.2rem;
    @include responsive(768) {
      margin-bottom: 1rem; }

    a {
      display: none;
      padding: 0.5rem;
      font-weight: 500;
      color: $br-darker-primary;
      @include responsive(768) {
        display: inline-block; } } }

  &__logo {
    height: 72px;
    @include responsive(768) {
      height: 36px; } }

  .swiper-slide {

    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover; } } }
