.navigation {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  border-top: 2px solid $br-lighter-gray;
  transition: all ease-in-out .3s;
  @include responsive(768) {
    display: none; }

  &.is-fixed {
    position: fixed;
    top: 0;
    bottom: auto;
    border-top: 0;
    box-shadow: 0 4px 8px transparentize($br-darker-gray, .9);
    background-color: #fff;

    .navigation__logo {
      display: inline-block; } }

  &__wrapper {
    @extend %flex;
    align-items: center;
    justify-content: flex-start; }

  ul {
    margin-bottom: 0;
    font-size: 1.125rem;
    font-weight: 500;
    @include responsive(1600) {
      max-width: 90%; }

    li {
      display: inline-block;
      margin-right: .5rem;

      a {
        display: inline-block;
        padding: 0.5rem;
        color: $br-darker-primary; } } }

  &__logo {
    display: none;
    margin-right: 1.5rem;

    img {
      display: block;
      height: 36px; } } }
