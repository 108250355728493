body {
  margin: 0;
  padding: 0; }

textarea {
  font-size: 1em;
  resize: none; }

input {
  font-size: 1em; }

/* Activate if necesary */
input, select, textarea, button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none; }

.wrapper {
  @extend %clearfix;
  @extend %bbx;
  position: relative;
  width: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto; }

.air {
  @extend %bbx;
  padding: 1.25rem;

  &_horz {
    padding: 0 1.25rem; }

  &_vert {
    padding: 1.25rem 0; } }

.backimg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; }

// Styled-chekbox: https://codepen.io/Vestride/pen/dABHx
