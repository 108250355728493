// ::: PROJECT VARIABLES :::
// project colors (br)
$br-darker-gray: #181715;
$br-dark-gray: #454545;
$br-light-gray: #AEA9A5;
$br-lighter-gray: #FBF7F4;
$br-primary: #E3000A;
$br-darker-primary: #D10C15;
$br-extra: #4A7A25;
$br-extra-2: #305ED4;

// ecommerce fonts (ft)
$ft-primary: 'Heebo', sans-serif;

// ::: SYSTEM VARIABLES :::
// neutral colors (ne)
$ne-base: #000;
$ne-10: lighten($ne-base, 10%);
$ne-20: lighten($ne-base, 20%);
$ne-30: lighten($ne-base, 30%);
$ne-40: lighten($ne-base, 40%);
$ne-50: lighten($ne-base, 50%);
$ne-60: lighten($ne-base, 60%);
$ne-70: lighten($ne-base, 70%);
$ne-80: lighten($ne-base, 80%);
$ne-85: lighten($ne-base, 85%);
$ne-90: lighten($ne-base, 90%);
$ne-95: lighten($ne-base, 95%);
$ne-100: lighten($ne-base, 100%);

// notification colors (nf)
$nf-error: #ff005c;
$nf-success: #00d64f;
$nf-warning: #ffdd2e;
$nf-info: #1373e4;
